import React from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(3),
      width: theme.spacing(50),
      height: theme.spacing(50),
    }
  },
  standardText: {
    color: 'black'
  },
  paper: {
    height: 'auto'
  }
}));

class BitcoinCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {

    // Update the count down every 1 second
    this.countdown = setInterval(
      () => this.count(),
      1000
    );

    fetch('https://blockchain.info/ticker')
        .then(response => response.json())
        .then(data => {
          
          var calcHoldings = 0.01244411;
          var currentHoldings = <em className="btc">0.01244411 BTC</em>;
          var price = data.EUR.last;
          var currentPrice = <em className="eur">{price} €</em>;
          var calcValue = (price * calcHoldings).toFixed(2);
          var currentValue = <em className="eur">{calcValue} €</em>;
          
          var diffValue = (calcValue/300 - 1) * 100;
          diffValue = diffValue.toFixed(2);

          var diffString;

          console.log(diffValue);

          if (diffValue < 0) {
            diffString = <em className="red">{diffValue.toString()}% </em>;
          } else {
            diffString = <em className="green">+{diffValue.toString()}% </em>;
          }

          var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
          var firstDate = new Date();
          var secondDate = new Date('December 1, 2021 12:00:00');
          
          var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

          this.setState({
            currentPrice: currentPrice,
            currentHoldings: currentHoldings,
            currentValue: currentValue,
            diffString: diffString,
            diffDays: diffDays
          });
        }
    )
  }

  count() {

    var countDownDate = new Date('December 1, 2030 12:00:00').getTime();
    var countdownValue = "";

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(this.countdown);
      countdownValue = "EXPIRED";
    }

    countdownValue = <em>{days}d {hours}h {minutes}m {seconds}s</em>

    this.setState({
      countdownValue: countdownValue
    });

  }

  render() {

    return (
      <Container maxWidth='sm'>
        <Content currentPrice={this.state.currentPrice} currentHoldings={this.state.currentHoldings} 
        currentValue={this.state.currentValue} diffString={this.state.diffString} countdownValue={this.state.countdownValue}/>
      </Container>
    );
  }
}

function Content(props) {
  const classes = useStyles();

  return (

    <div className={classes.main}>
        <Paper className={classes.paper} elevation={3}>
          <Box p={2}>
            <div><img className='center' src="./Bitcoin.png" width="75" height="75" alt="Bitcoin"/></div>
            <Typography className='standardText titleMargin' align='center' component="h2" variant="h4" gutterBottom>
              Christopher's Bitcoin
            </Typography>
            <Typography className='standardText' align='center' component="h2" variant="h6" >
              Current holdings: {props.currentHoldings}
            </Typography>
            <Typography className='standardText' align='center' component="h2" variant="h6" >
              Current BTC price: {props.currentPrice}
            </Typography>
            <Typography className='standardText' align='center' component="h2" variant="h6" >
              Current Value: {props.currentValue}
            </Typography>
            <Typography className='standardText' align='center' component="h2" variant="h6" >
              Change since purchase : {props.diffString}
            </Typography>
            <Typography className='standardText addMargin' align='center' component="h2" variant="subtitle2" >
              Days until payout: {props.countdownValue}
            </Typography>
          </Box>
        </Paper>
      </div>
  );
}

function App() {
  return (
    <BitcoinCard/>
  );
}

export default App;
